<template>
  <div>
    <v-form>
      <p class="mb-5">
        Match the glassware name to its picture and determine the precision associated with each
        piece.
      </p>
      <br />
      <v-simple-table>
        <thead></thead>
        <tbody>
          <tr>
            <td>{{ rowNames[0] }}</td>
            <td v-for="image in images" :key="image.alt">
              <img :src="image.img" :alt="image.alt" style="max-height: 150px" />
            </td>
          </tr>
          <tr>
            <td>{{ rowNames[1] }}</td>
            <td v-for="(option, index) in optionsNameShuffled" :key="option">
              <v-select
                :key="option"
                v-model="inputs.nameAnswers[index]"
                class="my-1"
                :items="optionsNameShuffled"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td>{{ rowNames[2] }}</td>
            <td v-for="(option, index) in optionsNameShuffled" :key="option">
              <v-select
                :key="option"
                v-model="inputs.precisionAnswers[index]"
                class="my-1"
                :items="optionsPrecision"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_1LC_Q1S_Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        nameAnswers: [],
        precisionAnswers: [],
      },
      rowNames: ['Image', '1. Name', '2. Precision of volume measurement'],
      images: [
        {
          img: '/img/assignments/ChemUCI_1LC_Q1S_Q1_optionB.png',
          alt: 'image of a beaker',
        },
        {
          img: '/img/assignments/ChemUCI_1LC_Q1S_Q1_optionA.png',
          alt: 'image of a Erlenmeyer flask',
        },
        {
          img: '/img/assignments/ChemUCI_1LC_Q1S_Q1_optionD.png',
          alt: 'image of a volumetric flask',
        },
        {
          img: '/img/assignments/ChemUCI_1LC_Q1S_Q1_optionC.png',
          alt: 'image of a graduated cylinder',
        },
      ],
      optionsName: [
        {
          text: 'beaker',
          value: 'beaker',
        },
        {
          text: 'graduated cylinder',
          value: 'graduated',
        },
        {
          text: 'Erlenmeyer flask',
          value: 'erlenmeyer',
        },
        {
          text: 'volumetric flask',
          value: 'volumetric',
        },
      ],
      optionsPrecision: [
        {
          text: 'imprecise',
          value: 'imprecise',
        },
        {
          text: 'moderately precise ',
          value: 'moderately',
        },
        {
          text: 'very precise ',
          value: 'very',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsNameShuffled() {
      return seededShuffle(this.optionsName, this.seed);
    },
  },
};
</script>
